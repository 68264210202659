@import "_mixins";

body {
  margin: 0;
}

@import "old-site-styles/normalize-old";
@import "old-site-styles/skeleton-old";
@import "old-site-styles/custom-old";

.section {
  padding: 10rem 0 11rem;
}

@import "sections/_top-info";
@import "sections/_compare";
@import "sections/_get-help";