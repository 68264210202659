.get-help {
  &__list {
    padding-top: 0;
  }

  &_image-column {
    width: 30%;

    @include tablets() {
      display: none;
    }
  }
}