.compare {
  padding-top: 50px;

  &__section {
    overflow: hidden;
    margin-bottom: 0;
    margin-top: 30px;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: white;

    &:first-child {
      margin-top: 0;
    }

    &:before, &:after {
      content: ' ';
      display: table;
    }
  }

  &__section-content {
    position: relative;
    z-index: 1;
    max-width: 940px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;

    &:before, &:after {
      content: ' ';
      display: table;
    }
  }

  &__show-more {
    text-align: center;
  }

  &__hide-section {
    display: block;
    position: fixed;
    top: 45vh;
    left: 30px;
    z-index: 10;
  }

  &__hide-button {
    color: #0FA0CE;
    font-weight: bold;
    font-size: 4rem;
    padding: .2rem;
    margin-bottom: 0;

    &:hover, &:active, &:focus {
      color: #0FA0CE;
    }
  }

  &__section-main-container {
    display: table;
    vertical-align: middle;
    width: 100%;
  }

  &__section-main-left-block {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    @include tablets() {
      display: none;
    }
  }

  &__section-main-rigth-block {
    display: table-cell;
    vertical-align: middle;
    width: 60%;
  }

  &__main-image {
    max-width: 200px;
  }

  &_iphone-se {
  }

  &_hidden {
    display: none;
  }

  &_hidden-section {
    display: none;
  }
}
.compare .section-capacity .capacity {
  display: inline-block;
  width: 45%;
  font-weight: 600;
}

.compare .section-capacity td p:nth-child(1) {
  margin-top: 0;
}

.compare .section-capacity td p {
  margin-bottom: 1em;
}

.compare__section-content h2 {
  font-size: 24px;
  line-height: 1.20849;
  font-weight: 500;
  letter-spacing: -.01em;
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  margin-bottom: 0px;
  padding-bottom: 8px;
  padding-left: 18px;
  border-bottom: 1px solid #d6d6d6;
  margin-top: 0;

  @include tablets(){
    font-size: 3.2rem;
  }
}

.compare__section-content table {
  width: 100%;
  table-layout: fixed;
}

.compare__section-content tr{
  vertical-align: top;
}

.compare__section-content tr:first-child td,
.compare__section-content tr:first-child th{
  padding-top: 23px;
}

.compare__section-content td:first-child, .compare__section-content th:first-child {
  border-left: none;
  padding-left: 15px;

  @include phones() {
    padding-left: 5px;
  }
}

.compare__section-content th {
  padding-bottom: 5px;
  border-bottom: none;
}

.compare__section-content h3 {
  color: #333333;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  letter-spacin: -.01em;
}

.compare__section-content td, .compare__section-content th {
  width: 20%;
  float: none;
  padding: 18px 18px 0;
  border-left: solid 1px #d6d6d6;
  text-align: left;
  border-bottom: none;

  &:first-child {
    padding-top: 23px;
    border-left: none;
  }

  @include phones() {
    padding-left: 5px;
    padding-right: 5px;
  }
}



.compare__section-content td p {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: -.01em;
  margin-bottom: 0;
}

.compare__section .table th p {
  padding-right: 0;
}

.compare__section .section-chips figure {
  margin: 14px 0 24px
}

.compare__section .image-a9-chip {
  width: 120px;
  height: 120px;
  background-size: 120px 120px;
  background-repeat: no-repeat;
  background-image: url("../img/a9_m9_chip_large.png");

  @include phones() {
    width: 100%;
    background-size: contain;
    margin: 5px;
  }
}

.nowrap {
  display: inline-block;
  text-decoration: inherit;
  white-space: nowrap
}

.lf-only-l {
  @include desktop() {
    display: inline;
  }
}

.lf-only-m, .lf-only-s, .lf-only-l {display: none;}

.compare__title {
  font-size: 40px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 50px;

  @include tablets() {
    font-size: 30px;
  }
}

.compare__section .section-title figure.iphone-logo {
  margin: -1px auto 0
}

.compare__section .section-title figure {
  margin: auto
}

.compare__section .image-iphone-6s-plus-logo {
  width: 160px;
  height: 20px;
  background-size: 160px 20px;
  background-repeat: no-repeat;
  background-image: url("../img/icon_iphone6s_plus_large.png");
  margin: 15px auto 15px;

  @include phones() {
    background-size: contain;
    width: 100%;
  }
}

.compare__section .image-iphone-6s-logo {
  width: 112px;
  height: 20px;
  background-size: 112px 20px;
  background-repeat: no-repeat;
  background-image: url("../img/icon_iphone6s_large.png");
  margin: 10px auto 15px;
}

.compare__section .image-iphone-se-logo {
  width: 95px;
  height: 20px;
  background-size: 95px 20px;
  background-repeat: no-repeat;
  background-image: url(../img/icon_iphoneSE_large.png);
  margin: 10px auto 15px;
}

.compare__section.section-title .compare__section-content span {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.compare__section.section-title table {
  border-bottom: 1px solid lightgrey;
  margin-bottom: 0;
}

.compare .section-title {
  z-index: 5;
  top: 0
}

.compare .section-title table th {
  padding-top: 0;
  text-align: center;
  padding-bottom: 0;
}

.compare .section-title .compare__section-content {
  padding-top: 15px;
}

