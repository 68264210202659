%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

%verticalAlignedInlineBlock {
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

//responsive
@function rem($px) {
  @return $px / 16px + 0rem;
}


@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}


@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}


@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}